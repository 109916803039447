//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import testimonies from '~/data/testimonies';

import { getAllCourses } from '~/data/courses'

export default {
  name: "IndexPage",
  layout: "default",
  components: {},
  head() {
    return {
      title: 'Cursos de testing de software',
      meta: [
        { hid: 'description', name: 'description', content: 'Aprende desde donde te encuentres, con los mejores instructores y a tu propio ritmo.Descubre nuestros cursos de testing de software.' },
        { hid: 'keywords', name: 'keywords', content: 'cursos testing' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  async asyncData() {
    const courses = await getAllCourses();
    return { courseList: courses }
  },
  data() {
    return {
      searchText: "",
      testimonyList: [
        testimonies.monicaSilvestri,
        testimonies.diegoSastre,
        testimonies.carinnaBalsamo,
        testimonies.ricardoDelano,
      ]
    };
  },
  computed: {
    featuredCourses() {
      return [
        this.courseList['software-tester'],
        this.courseList['especializacion-en-testing-funcional-y-accesibilidad'],
        this.courseList['especialización-en-automatizacion-de-pruebas'],
        this.courseList['pruebas-de-accesibilidad-web-y-movil'],
        this.courseList['performance-testing-con-jmeter-y-blazemeter'],
        this.courseList['katalon-para-web-testing'],
      ]
    },
    autocompleteCourseList() {
      return Object.entries(this.courseList).map(([key, course]) => {
        return {
          name: course.shortName,
          img: course.thumbnail,
          to: this.localePath(`/cursos/${course.slug}`),
          category: course.category
        }
      })
    },
    autocompleteCourseListNoResult() {
      return [
        this.courseList['software-tester'],
        this.courseList['mobile-testing'],
        this.courseList['pruebas-de-accesibilidad-web-y-movil']
      ].map(course => {
        return {
          name: course.shortName,
          img: course.thumbnail,
          to: this.localePath(`/cursos/${course.slug}`)
        }
      })
    }
  },
  methods: {

  }
};
